.navbarContainer.navbar::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    z-index: -1;
}

.navbarContainer {
    background-color: rgba(0, 0, 0, 0.75);

    .nav-link {
        font-weight: 600;
        color: var(--textColor) !important;
    }

    .dropdown-menu {
        background-color: rgba(0, 0, 0, 0.75);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 0;
        margin: 0;
        margin-top: 0.5rem;
        overflow: hidden;
    }

    .dropdown-menu::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(3px);
        z-index: -1;
    }
}
