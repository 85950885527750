@font-face {
    font-family: BebasNeue;
    src: url("./fonts/BebasNeue.ttf");
}

@font-face {
    font-family: Rubik;
    src: url("./fonts/Rubik.ttf");
}

html {
    --backgroundColor: #090e12;
    --textColor: #f7fff7;
    --highlightColor: #ffe66d;
    --accentColor: #6ca6c1;
}

body {
    margin: 0;
    font-family: Rubik, sans-serif;

    background: linear-gradient(-45deg, var(--backgroundColor), #0d0912, #120910, #180f1e);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
