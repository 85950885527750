.infoContainer {
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: white;
    font-family: Rubik;

    .carousel-caption {
        background-color: white;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 2em;
    }

    .carousel-caption p {
        color: black !important;
    }

    p {
        color: #242424;
    }

    h1,
    h2,
    h3,
    h4 {
        color: black;
    }

    h3 {
        font-size: 2rem;
    }

    ol,
    ul {
        color: black;
    }

    ol p,
    ul p {
        color: #242424;
    }

    .accordion-item,
    .accordion-header,
    .accordion-button {
        background-color: white;
        color: black;
    }

    .accordion-body {
        color: #242424;
    }

    &.secondary {
        background-color: #f9eeff;
    }
}
