.cardContainer {
    transition: transform 0.2s;

    .imageContainer {
        min-height: 10em;
    }

    .card-img-top {
        transition: transform 0.2s;

        max-width: 10em;
        object-fit: cover;
    }

    .overviewLink {
        font-size: 0.75em;
        color: gray;
    }

    .dataBox {
        background-color: rgba(0, 0, 0, 0.1);

        .mainText {
            color: white;
        }

        color: gray;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px;
        transform: scale(1.025);

        .card-img-top {
            transform: scale(1.1);
        }
    }
}
