.stickyCasinoBannerContainer {
    font-size: min(4vw, 1.25rem);
    line-height: 110%;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(3px);
    z-index: 1;
    transition: opacity 0.2s;

    @media (max-width: 576px) {
        .redirectButton {
            border-radius: 0;
        }
    }

    &.isHidden {
        pointer-events: none;
        opacity: 0;
    }
}
