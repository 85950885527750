.slotContainer {
    .imageContainer {
        width: 100%;
        aspect-ratio: 1 / 1;
        background-position: center;
        background-size: cover;
    }

    .overviewLink {
        font-size: 0.75em;
        color: gray;
    }

    .dataBox {
        background-color: rgba(0, 0, 0, 0.1);

        .mainText {
            color: white;
        }

        color: gray;
    }
}
