.bannerContainer {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);

    .logoContainer {
        height: 5em;
    }

    .overviewLink {
        font-size: 0.75em;
        color: gray;
    }

    .logo {
        width: 100%;
        height: 100%;

        img {
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }

    .bonus * {
        color: black;
    }

    .freeSpins * {
        color: #242424;
    }

    .dataBox {
        background-color: rgba(0, 0, 0, 0.05);

        .mainText {
            color: black;
        }

        .comment {
            color: #242424;
        }
    }

    &.lightTheme {
        background-color: #282828;

        .bonus * {
            color: white;
        }

        .freeSpins * {
            color: white;
        }

        .dataBox {
            background-color: rgba(0, 0, 0, 0.1);

            .mainText {
                color: white;
            }

            .comment {
                color: white;
            }
        }
    }
}
