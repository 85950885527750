.promotionCarouselContainer {
    .slide {
        height: 350px;
    }

    .carousel-caption {
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    img {
        max-height: 7em;
    }

    .btn {
        animation: breathe 2s ease-in-out infinite;
    }

    .caption h4,
    .caption h5 {
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }

    .casombie {
        .slide {
            background: url("./casombie_promo.png");
            background-size: cover;
            background-position: 50% 0%;
            background-repeat: no-repeat;
            mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
        }
    }

    .verde {
        .slide {
            background: url("./verde_promo.jpg");
            background-size: cover;
            background-position: 75% 0%;
            background-repeat: no-repeat;
            mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
        }
    }

    .myempire {
        .slide {
            background: url("./myempire_promo.png");
            background-size: cover;
            background-position: 50% 0%;
            background-repeat: no-repeat;
            mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
        }
    }
}

@keyframes breathe {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.025);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px;
    }
    100% {
        transform: scale(1);
    }
}
