.overviewContainer {
    .infoSection {
        background-color: #282828;
    }

    .list-group-item {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.reviewContainer {
    h2 {
        padding-top: 3rem;
    }
}
